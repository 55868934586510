import { BarChart2, SquareChevronRight } from 'lucide-react'

export function APICreditsUsageIcon() {
    return (
        <div className="relative w-14 h-14">
            <div className="bg-red-100 rounded-md w-12 h-10 flex justify-center items-center">
                <BarChart2 className="text-red-500" size={24} />
            </div>
            <APICreditsIcon className="absolute -top-2 -left-2" size={6} />
        </div>
    )
}

export function APICreditsIcon({ className = '', size = 6 }: { className?: string; size?: number }) {
    return (
        <div className={`bg-red-600 rounded-full w-${size} h-${size} flex justify-center items-center ${className}`}>
            <SquareChevronRight className={`text-white w-${size - 2} h-${size - 2}`} />
        </div>
    )
}
