import { Braces, Copy, ExternalLink } from 'lucide-react'
import { Button } from '@/shared/ui/base/button'
import { WithTooltip } from '../../../../shared/ui/base/tooltip'
import { EVENT_API_CURL_MODAL_COPY, EVENT_API_CURL_MODAL_VIEW, sendEvent } from '@/services/tracking.service'
import { Sheet, SheetContent, SheetTrigger } from '../../base/sheet'

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { vscDarkPlus } from 'react-syntax-highlighter/dist/cjs/styles/prism'
import { Separator } from '@/shared/ui/base/separator'
import { UserWithTeam } from '@/schemas/entities/user'
import { useQuery } from '@tanstack/react-query'
import { getMyProfile } from '@/services/user.service'
import { HOUR_IN_MS } from '@/shared/utils/date-utils'
import { useToast } from '../../base/use-toast'
import { Link } from 'react-router-dom'

export default function APICurlButton({ curl, className }: { curl: string; className?: string }) {
    const { toast } = useToast()
    const { data: me = null } = useQuery<UserWithTeam>({
        queryKey: ['me'],
        queryFn: getMyProfile,
        staleTime: HOUR_IN_MS,
    })
    ;``

    const copyCurlToClipboard = () => {
        let code = curl
        if (me) {
            code = code.replace('<api_key>', me.token)
        }
        navigator.clipboard.writeText(code)
        toast({ title: 'Copied to clipboard' })
    }

    return (
        <div className={`${className}`}>
            <Sheet>
                <WithTooltip title="Pull this list with our API">
                    <SheetTrigger asChild>
                        <Button
                            variant="ghost"
                            size="sm"
                            className="text-sm font-normal"
                            onClick={() => {
                                sendEvent(EVENT_API_CURL_MODAL_VIEW)
                            }}
                        >
                            <Braces className="h-4 w-4 md:mr-2" />
                            <p className="hidden lg:inline">API</p>
                        </Button>
                    </SheetTrigger>
                </WithTooltip>
                <SheetContent className="w-full md:w-2/3 lg:w-3/5 xl:w-2/5 overflow-y-auto">
                    <h4 className="text-lg font-medium mb-4">Pull this list with our API</h4>

                    <div className="mt-4">
                        <div className="flex gap-2 mb-2 justify-end">
                            <Link to={`/settings/api`} target="_blank">
                                <Button size="xs" variant="outline">
                                    API Key
                                </Button>
                            </Link>
                            <Button
                                size="xs"
                                variant={'outline'}
                                onClick={() => {
                                    sendEvent(EVENT_API_CURL_MODAL_COPY)
                                    window.open('https://api.theirstack.com', '_blank')
                                }}
                            >
                                <ExternalLink className="mr-2 h-4 w-4" />
                                API Docs
                            </Button>
                            <Button size="xs" variant="outline" onClick={copyCurlToClipboard}>
                                <Copy className="mr-2 h-4 w-4" />
                                cURL
                            </Button>
                        </div>
                        <div className="flex flex-col bg-[#1E1E1E] rounded-md">
                            <div className="flex justify-between">
                                <p className="text-sm text-white p-2">
                                    <span className="text-green-500 mr-2">POST</span>{' '}
                                    {curl.split(import.meta.env.VITE_THEIRSTACK_API_SERVER_URL)[1].split('"')[0]}
                                </p>
                            </div>
                            <Separator className="bg-white" />
                            <SyntaxHighlighter
                                className="max-h-[400px] overflow-scroll m-0"
                                language="bash"
                                style={vscDarkPlus}
                                showLineNumbers={true}
                            >
                                {curl}
                            </SyntaxHighlighter>
                        </div>
                    </div>
                </SheetContent>
            </Sheet>
        </div>
    )
}
