import { getHTTPHeadersImpersonating } from '@/lib/authentication/authentication-http-headers'
import { constructURL } from '@/lib/http-utils'
import { RecentSearch, SearchType, recentSearchSchema } from '@/schemas/entities/recent-search.schema'
import { z } from 'zod'

const RESOURCE_URL = import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + '/v0/requests/'

export async function getAllRecentSearches(
    type: SearchType | undefined,
    page: number,
    user_id?: number
): Promise<RecentSearch[]> {
    const url = constructURL(RESOURCE_URL, {
        types: type ? [type] : ['jobs', 'companies'],
        user_id_or: user_id,
        page: page,
    })
    const res = await fetch(url, { method: 'GET', headers: getHTTPHeadersImpersonating() })
    if (!res.ok) throw new Error('Failed to fetch data')
    return z.array(recentSearchSchema).parse(((await res.json()) as { data: RecentSearch[] }).data)
}
