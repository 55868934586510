import { Separator } from '@/shared/ui/base/separator'
import { useEffect } from 'react'

function ProfilePage() {
    useEffect(() => {
        document.title = 'Profile · Settings · TheirStack'
    }, [])

    return (
        <div className="space-y-6">
            <div>
                <h3 className="text-lg font-medium">Profile</h3>
                <p className="text-sm text-muted-foreground">Manage your profile information.</p>
            </div>
            <Separator />
        </div>
    )
}

export default ProfilePage
