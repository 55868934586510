import { cn } from '@/lib/utils'
import { Job } from '@/schemas/entities/job.schema'
import { LinkedInLogoIcon } from '@radix-ui/react-icons'

export default function HiringManagerCard({ job }: { job: Job }) {
    if (job.hiring_team === undefined || job.hiring_team?.length === 0) {
        return <p></p>
    }
    const hiring_manager = job.hiring_team?.[0]
    return (
        <div className={cn('grid grid-flow-row auto-cols-max items-center', job.has_blurred_data && 'blur-sm')}>
            <div className="grid grid-flow-col gap-2 auto-cols-max items-center">
                <p className="font-medium text-gray-900 max-w-56 truncate">{hiring_manager?.full_name}</p>
                {hiring_manager?.linkedin_url && (
                    <>
                        {!job.has_blurred_data && (
                            <a href={hiring_manager.linkedin_url} target="_blank" className="text-gray-500">
                                <LinkedInLogoIcon />
                            </a>
                        )}
                        {job.has_blurred_data && (
                            <p>
                                <LinkedInLogoIcon className="text-gray-500" />
                            </p>
                        )}
                    </>
                )}
            </div>
            <p className="font-light text-xs text-gray-900 truncate max-w-56">
                {hiring_manager?.role?.substring(0, 50)}{' '}
            </p>
        </div>
    )
}
