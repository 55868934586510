import { getHTTPHeadersImpersonating } from '@/lib/authentication/authentication-http-headers'
import { TeamMember, teamMemberSchema } from '@/schemas/entities/team-members.schema'
import { z } from 'zod'

const RESOURCE_URL = import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + '/v0/teams'

export async function getTeamMembers(): Promise<TeamMember[]> {
    const res = await fetch(RESOURCE_URL + '/current/members', {
        method: 'GET',
        headers: getHTTPHeadersImpersonating(),
    })
    if (!res.ok) throw new Error('Failed to fetch data')
    return z.array(teamMemberSchema).parse(await res.json())
}
