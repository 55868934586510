import { cn } from '@/lib/utils'
import { UserWithTeam } from '@/schemas/entities/user'
import { getMyProfile } from '@/services/user.service'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/shared/ui/base/dialog'
import { THERE_MINUTE_IN_MS } from '@/shared/utils/date-utils'
import { formatNumberWithComma } from '@/shared/utils/number-utils'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Row, Table } from '@tanstack/react-table'
import { ChevronDown } from 'lucide-react'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { Button } from '../../../shared/ui/base/button'
import { Checkbox } from '../../../shared/ui/base/checkbox'
import { Popover, PopoverContent, PopoverTrigger } from '../../../shared/ui/base/popover'
import { Separator } from '../../../shared/ui/base/separator'
import { Spinner } from '../../../shared/ui/base/spinner'
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from '../base/alert-dialog'
import { Form, FormControl, FormField, FormItem, FormMessage } from '../base/form'
import { Input } from '../base/input'
import { Progress } from '../base/progress'
import { OpenDialogHandle } from '../dialog/dialog-handle-interface'
import OutOfCreditsDialog from '../dialog/out-of-credits-dialog'
import UpgradeButton from '../layout/components/upgrade_button'
import { isChecked } from './columns/column-utils'

const BATCH_SIZE = 200

export default function SelectAllCheckbox<TData>({
    table,
    hasBlurredData,
}: {
    table: Table<TData>
    hasBlurredData: (row: Row<TData>) => boolean
}) {
    const [isOpen, setIsOpen] = useState(false)
    const outOfCreditsDialogRef = useRef<OpenDialogHandle>(null)
    const { rows } = table.getRowModel()
    const reveleaded_rows = rows.filter((row) => !hasBlurredData(row))
    return (
        <div className="-ml-2">
            <Popover open={isOpen} onOpenChange={setIsOpen}>
                <PopoverTrigger asChild>
                    <div className="hover:bg-white rounded-sm p-1 inline-flex items-center cursor-pointer">
                        <Checkbox className="ml-1" checked={isChecked(table)} aria-label="Select all" />
                        <ChevronDown className="h-5 w-5 ml-1" />
                    </div>
                </PopoverTrigger>
                <PopoverContent align="start" className="flex flex-col gap-1 p-1">
                    <Button
                        variant="ghost"
                        size="xs"
                        className="justify-start font-normal"
                        onClick={() => {
                            table.toggleAllPageRowsSelected(true)
                            setIsOpen(false)
                        }}
                        disabled={isChecked(table) == true}
                    >
                        Select page ({rows.length})
                    </Button>
                    {reveleaded_rows.length > 0 && reveleaded_rows.length != rows.length && (
                        <Button
                            variant="ghost"
                            size="xs"
                            className="justify-start font-normal"
                            disabled={reveleaded_rows.length == 0}
                            onClick={() => {
                                rows.forEach((row) => row.toggleSelected(!hasBlurredData(row) || false))
                                setIsOpen(false)
                            }}
                        >
                            Select revealed records ({reveleaded_rows.length})
                        </Button>
                    )}
                    <Separator />
                    <SelectCustomNumberOption
                        table={table}
                        onBulkSelectionFinished={() => {
                            setIsOpen(false)
                            table.toggleAllPageRowsSelected(true)
                        }}
                        onOutOfCredits={() => outOfCreditsDialogRef.current?.open()}
                        disabled={
                            rows.length == (table.options?.meta?.getTotalResults() || 0) ||
                            (table.options?.meta?.getTotalResults() || 0) === -1
                        }
                    />
                    <Separator />
                    <Button
                        variant="ghost"
                        size="xs"
                        className="justify-start font-normal"
                        disabled={isChecked(table) == false}
                        onClick={() => {
                            table.toggleAllPageRowsSelected(false)
                            setIsOpen(false)
                        }}
                    >
                        Clear selection
                    </Button>
                </PopoverContent>
            </Popover>
            <OutOfCreditsDialog ref={outOfCreditsDialogRef} />
        </div>
    )
}

function SelectCustomNumberOption<TData>({
    table,
    onBulkSelectionFinished,
    onOutOfCredits,
    disabled,
}: {
    table: Table<TData>
    onBulkSelectionFinished: () => void
    onOutOfCredits: () => void
    disabled: boolean
}) {
    const confirmBulkActionRef = useRef<OpenDialogHandle>(null)
    const [progress_percentage, setProgressPercentage] = useState(0)
    const [credits, setCredits] = useState<number>()
    const [isCalculatingCredits, setIsCalculatingCredits] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [totalResults, setTotalResults] = useState<number>(0)
    const queryClient = useQueryClient()

    const formSchema = z.object({
        numberField: z.coerce
            .number()
            .min(26, 'Value must be greater than 25')
            .max(totalResults, `Value must be less than ${totalResults}`),
    })

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            numberField: totalResults,
        },
    })

    useEffect(() => {
        if (table.options?.meta?.getTotalResults && table.options?.meta?.getTotalResults() != -1) {
            setTotalResults(table.options?.meta?.getTotalResults() || 0)
            form.setValue('numberField', table.options?.meta?.getTotalResults() || 0)
        }
    }, [table.options?.meta?.getTotalResults])

    function onSubmit() {
        if (!table.options?.meta?.calculateCredits) return
        setIsCalculatingCredits(true)
        table.options?.meta?.calculateCredits(+form.getValues().numberField).then((credits) => {
            setCredits(credits)
            setIsCalculatingCredits(false)
            if (credits == 0) {
                onConfirm()
            } else {
                confirmBulkActionRef.current?.open()
            }
        })
    }

    function onConfirm() {
        setIsOpen(true)
        fetchRecords()
        confirmBulkActionRef.current?.close()
    }

    async function fetchRecords() {
        if (!table.options?.meta?.fetchAndAddRowsIncrementally) return
        const total_records = form.getValues().numberField
        const total_batches = Math.ceil(total_records / BATCH_SIZE)
        let current_batch = 0
        for (let i = 0; i < total_batches; i++) {
            const batch_size = Math.min(BATCH_SIZE, total_records - i)
            const response = await table.options?.meta?.fetchAndAddRowsIncrementally(i, batch_size)
            setProgressPercentage((current_batch / total_batches) * 100)
            if (response.is_out_of_credits) {
                onOutOfCredits()
                setIsOpen(false)
                queryClient.invalidateQueries({ queryKey: ['me'] })
                return
            }
            await new Promise((resolve) => setTimeout(resolve, 1000))
            current_batch++
        }
        onBulkSelectionFinished()
        setIsOpen(false)
        queryClient.invalidateQueries({ queryKey: ['me'] })
    }

    return (
        <div className="flex flex-col">
            <Form {...form}>
                <form className="flex flex-col px-2 py-1" onSubmit={form.handleSubmit(onSubmit)}>
                    <FormField
                        control={form.control}
                        name="numberField"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <div className="flex items-center justify-between mb-2">
                                        <p className={cn('text-sm', disabled && 'opacity-50')}>
                                            Select number of records
                                        </p>
                                        <Input
                                            className="w-20 h-8 arrow-hide"
                                            type="number"
                                            disabled={disabled}
                                            {...field}
                                        />
                                    </div>
                                </FormControl>
                                <FormMessage className="pb-2" />
                            </FormItem>
                        )}
                    />

                    <Button type="submit" size="xs" disabled={disabled}>
                        {isCalculatingCredits && <Spinner size="sm" />}
                        {!isCalculatingCredits && 'Apply'}
                    </Button>
                </form>
            </Form>

            <ConfirmBulkAction
                ref={confirmBulkActionRef}
                confirmAction={onConfirm}
                n_companies={+form.getValues().numberField}
                credits={credits || 0}
            />
            <SelectingDialog
                total_records={+form.getValues().numberField}
                isOpen={isOpen}
                progress_percentage={progress_percentage}
            />
        </div>
    )
}

function SelectingDialog({
    isOpen,
    total_records,
    progress_percentage,
}: {
    isOpen: boolean
    total_records: number
    progress_percentage: number
}) {
    return (
        <Dialog open={isOpen}>
            <DialogContent onInteractOutside={() => {}} onEscapeKeyDown={() => {}} allowToClose={false}>
                <DialogHeader>
                    <DialogTitle>Bulk selection</DialogTitle>
                    <DialogDescription className="pt-2">
                        Downloading {formatNumberWithComma(total_records)} records, this may take a few minutes. Please
                        wait.
                    </DialogDescription>
                </DialogHeader>
                <Progress value={progress_percentage} />
            </DialogContent>
        </Dialog>
    )
}

export interface ConfirmBulkActionProps {
    n_companies: number
    credits: number
    confirmAction: () => void
    cancelAction?: () => void
}

const ConfirmBulkAction = forwardRef<OpenDialogHandle, ConfirmBulkActionProps>(
    ({ credits, n_companies, confirmAction }, ref) => {
        const { data: me } = useQuery<UserWithTeam>({
            queryKey: ['me'],
            queryFn: getMyProfile,
            staleTime: THERE_MINUTE_IN_MS,
        })
        const [show, setShow] = useState(false)
        useImperativeHandle(ref, () => ({
            open: () => setShow(true),
            close: () => setShow(false),
        }))
        const credits_after_operation = (me?.team?.credits_left_current_period || 0) - credits

        return (
            <AlertDialog open={show} onOpenChange={(open) => setShow(open)}>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>
                            {credits_after_operation >= 0 && (
                                <span>Do you want to select in bulk {formatNumberWithComma(n_companies)} records?</span>
                            )}

                            {credits_after_operation < 0 && <span>Bulk selection</span>}
                        </AlertDialogTitle>
                        <AlertDialogDescription>
                            {credits_after_operation >= 0 && (
                                <span>
                                    This action will consume {formatNumberWithComma(credits)} credit{credits > 1 && `s`}{' '}
                                    from your account. You have{' '}
                                    {formatNumberWithComma(me?.team?.credits_left_current_period)} credit
                                    {me?.team?.credits_left_current_period != 1 && `s`} left.
                                </span>
                            )}
                            {credits_after_operation < 0 && (
                                <>
                                    <span>
                                        You don't have enough company credits (
                                        {formatNumberWithComma(me?.team?.credits_left_current_period)}) to perform this
                                        action ({formatNumberWithComma(credits)} credits), please consider one of the
                                        following options:
                                    </span>
                                    <ul className="list-disc ml-6 mt-2">
                                        <li>Upgrade your plan or buy some extra company credits</li>
                                        <li>Select a smaller number of records</li>
                                        <li>
                                            Add filter "Is Company Revealed" = "Yes" to see only companies you’ve
                                            already consumed company credits for
                                        </li>
                                    </ul>
                                </>
                            )}
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        {credits_after_operation >= 0 && (
                            <AlertDialogAction onClick={confirmAction} disabled={credits_after_operation < 0}>
                                Continue
                            </AlertDialogAction>
                        )}
                        {credits_after_operation < 0 && <UpgradeButton variant="default" />}
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        )
    }
)
