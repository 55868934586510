import { Button } from '@/shared/ui/base/button'
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator,
} from '@/shared/ui/base/command'
import { Popover, PopoverContent, PopoverTrigger } from '@/shared/ui/base/popover'
import { FilterGroup, IFiltersProps } from '../../../shared/schemas/entity/filter.schema'
import { WithTooltip } from '@/shared/ui/base/tooltip'
import { BriefcaseBusiness, Building2, Laptop, LucideHelpCircle, Plus } from 'lucide-react'
import { Separator } from '@/shared/ui/base/separator'
import { useState } from 'react'
import { EVENT_FILTERS_MODAL_OPEN, sendEvent } from '@/services/tracking.service'

const ButtonAddFilter = ({
    availableFilters,
    appliedFilters,
    addFilters,
}: {
    availableFilters: IFiltersProps[]
    appliedFilters: IFiltersProps[]
    addFilters: (_filters: IFiltersProps[]) => void
}) => {
    const groups = availableFilters
        .map((filter) => filter.group)
        .filter((value, index, self) => self.indexOf(value) === index)
    const [search, setSearch] = useState<string>()
    const [groupFilter, setGroupFilter] = useState<FilterGroup>()

    const onFilterByGroup = (group: FilterGroup) => {
        if (groupFilter == group) {
            setGroupFilter(undefined)
            return
        }
        setGroupFilter(group)
    }

    const addFilter = (filter: IFiltersProps) => {
        const newFilters = []

        //checking if the filter has a dependency
        if (filter.dependsOnFilterIds && filter.dependsOnFilterIds?.length > 0) {
            const exists_any_dependency = filter.dependsOnFilterIds.some((dependencyId) =>
                appliedFilters.some((appliedFilter) => appliedFilter.id === dependencyId)
            )
            if (!exists_any_dependency) {
                const firstDependency = availableFilters.find(
                    (availableFilter) =>
                        filter.dependsOnFilterIds && filter.dependsOnFilterIds.includes(availableFilter.id)
                )
                if (firstDependency) {
                    const dependencyFilter = { ...firstDependency }
                    dependencyFilter.operator =
                        dependencyFilter.availableOperators && dependencyFilter.availableOperators[0]
                    dependencyFilter.value = 15
                    dependencyFilter.hasChanged = true
                    newFilters.push(dependencyFilter)
                }
            }
        }

        // adding selected filter
        const newFilter = { ...filter }
        const appliedFiltersWithSameId = appliedFilters.filter((appliedFilter) => appliedFilter.id === filter.id)
        const operatorsUsed = appliedFiltersWithSameId.map((appliedFilter) => appliedFilter.operator)
        newFilter.operator = newFilter.availableOperators.filter((operator) => !operatorsUsed.includes(operator))[0]
        newFilters.push(newFilter)

        addFilters(newFilters)
    }

    function isApplied(filter: IFiltersProps) {
        const appliedFiltersWithSameId = appliedFilters.filter((appliedFilter) => appliedFilter.id === filter.id)
        return (
            appliedFiltersWithSameId.length == filter.availableOperators.length ||
            (appliedFiltersWithSameId.length == 1 && filter.areOperatorsAccumulative == false)
        )
    }

    const filterCountByGroup = groups.reduce((countByGroup: any, group) => {
        const filtersInGroup = availableFilters.filter((filter) => filter.group === group)
        countByGroup[group] = filtersInGroup.length
        return countByGroup
    }, {})

    const onPopOverOpenChange = (open: boolean) => {
        if (open) {
            sendEvent(EVENT_FILTERS_MODAL_OPEN)
        } else {
            setGroupFilter(undefined)
        }
    }

    return (
        <Popover onOpenChange={onPopOverOpenChange}>
            <PopoverTrigger asChild>
                <Button variant="ghost" size="sm">
                    {' '}
                    <Plus className="mr-2 h-4 w-4" /> Add Filter
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[400px] p-0 " align="start">
                <Command>
                    <CommandInput
                        placeholder="Type filter..."
                        onValueChange={setSearch}
                        onClick={() => {
                            setGroupFilter(undefined)
                        }}
                    />
                    <div className="grid grid-cols-3 m-1 space-x-1">
                        {groups.map((group) => (
                            <div
                                key={group}
                                className={`flex items-center flex-col p-2 cursor-pointer space-y-2 ${groupFilter == group ? 'text-primary rounded-md bg-secondary' : 'hover:text-primary hover:bg-gray-100 hover:rounded-md text-muted-foreground'}`}
                                onClick={() => onFilterByGroup(group)}
                            >
                                {group == 'Company' && <Building2 className="h-5 w-5" />}
                                {group == 'Technology' && <Laptop className="h-5 w-5" />}
                                {group == 'Job Posting' && <BriefcaseBusiness className="h-5 w-5" />}
                                <p className="text-xs font-medium">
                                    {group} ({filterCountByGroup[group]})
                                </p>
                            </div>
                        ))}
                    </div>
                    <Separator />
                    <CommandList>
                        <CommandEmpty>No results found for "{search}"</CommandEmpty>
                        {groups
                            .filter((group) => groupFilter == undefined || groupFilter == group)
                            .map((group) => (
                                <CommandGroup heading={group} key={group}>
                                    {availableFilters
                                        .filter((filter) => filter.group == group)
                                        .map((filter, index) => (
                                            <CommandItem
                                                className={isApplied(filter) ? 'text-gray-400 group' : 'group'}
                                                key={index}
                                                onSelect={() => addFilter(filter)}
                                                disabled={isApplied(filter)}
                                            >
                                                <filter.icon className="mr-2 h-3 w-3" />
                                                <span>{filter.title}</span>
                                                {filter.description && (
                                                    <WithTooltip title={filter.description} side="right">
                                                        <LucideHelpCircle className="ml-2 h-3 w-3 text-muted-foreground group-hover:opacity-100 opacity-0" />
                                                    </WithTooltip>
                                                )}
                                            </CommandItem>
                                        ))}
                                </CommandGroup>
                            ))}
                        <CommandSeparator />
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}

export default ButtonAddFilter
