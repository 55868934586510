import { BarChart2, Building2 } from 'lucide-react'

export function CompanyCreditsUsageIcon() {
    return (
        <div className="relative w-14 h-14">
            <div className="bg-blue-100 rounded-md w-12 h-10 flex justify-center items-center">
                <BarChart2 className="text-blue-500" size={24} />
            </div>
            <CompanyCreditsIcon className="absolute -top-2 -left-2" size={6} />
        </div>
    )
}

export function CompanyCreditsIcon({ className = '', size = 6 }: { className?: string; size?: number }) {
    return (
        <div className={`bg-blue-600 rounded-full w-${size} h-${size} flex justify-center items-center ${className}`}>
            <Building2 className={`text-white w-${size - 2} h-${size - 2}`} />
        </div>
    )
}
