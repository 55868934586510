import { WithTooltip } from '@/shared/ui/base/tooltip'
import { formatNumberWithComma } from '@/shared/utils/number-utils'
import CreditsIcon from './credits-icon'

export default function CreditsBadge({ credits }: { credits: number }) {
    return (
        <WithTooltip title={`This action costs ${formatNumberWithComma(credits)} company credits.`}>
            <div className="inline-grid grid-flow-col justify-start items-center gap-1 bg-green-200 rounded-md px-1 py-1 cursor-pointer">
                <span className="text-green-800 text-xs font-medium">{formatNumberWithComma(credits)}</span>
                <CreditsIcon className="text-green-800 w-4 h-4" />
            </div>
        </WithTooltip>
    )
}
