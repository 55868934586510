import { Input } from '@/components/ui/base/input'
import { DataTableFacetedFilter } from '@/components/ui/table/data-table-faceted-filter'
import { Table } from '@tanstack/react-table'
import { Laptop } from 'lucide-react'
interface DataTableToolbarProps<TData> {
    table: Table<TData>
    filter_by?: string
}
export function DataTableToolbarTechnologies<TData>({ table }: DataTableToolbarProps<TData>) {
    return (
        <div className="flex items-center flex-wrap gap-2">
            <Input
                placeholder="Filter by technology..."
                value={(table.getColumn('name')?.getFilterValue() as string) ?? ''}
                onChange={(event) => table.getColumn('name')?.setFilterValue(event.target.value)}
                className="h-8 w-[150px] lg:w-[250px]"
            />
            <DataTableFacetedFilter
                icon={Laptop}
                column={table.getColumn('category_name')}
                title="Technology Category"
                options={
                    Array.from(table.getColumn('category_name')?.getFacetedUniqueValues() ?? new Map())
                        .map((item) => item[0])
                        .filter((item) => item != null)
                        .sort()
                        .map((value) => ({ value: value, label: value })) ?? []
                }
            />
        </div>
    )
}
