import * as React from 'react'
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts'

import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/shared/ui/base/card'
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/base/chart'
import { CreditsConsumption } from '@/schemas/entities/credits-consumption.schema'
import formatNumberToNDigits, { formatNumberWithComma } from '@/shared/utils/number-utils'

export const description = 'An interactive bar chart'

const chartConfig = {
    companies: {
        label: 'Companies',
        color: 'hsl(var(--chart-1))',
    },
    jobs: {
        label: 'Jobs',
        color: 'hsl(var(--chart-2))',
    },
} satisfies ChartConfig

export function APIRequestChart({ data }: { data: CreditsConsumption[] }) {
    const chart_data = data.map((item) => ({
        date: item.period_start.split('T')[0],
        companies: item.number_of_companies,
        jobs: item.number_of_jobs,
    }))
    const [activeChart, setActiveChart] = React.useState<keyof typeof chartConfig | null>(null)

    const total = React.useMemo(
        () => ({
            companies: chart_data.reduce((acc, curr) => acc + curr.companies, 0),
            jobs: chart_data.reduce((acc, curr) => acc + curr.jobs, 0),
        }),
        [data]
    )

    return (
        <Card>
            <CardHeader className="flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row">
                <div className="flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6">
                    <CardTitle className="text-lg">Records returned API requests</CardTitle>
                    <CardDescription>
                        Showing total records returned from API requests in the last month
                    </CardDescription>
                </div>
                <div className="flex">
                    {['companies', 'jobs'].map((key) => {
                        const chart = key as keyof typeof chartConfig
                        return (
                            <button
                                key={chart}
                                data-active={activeChart === chart}
                                className="relative z-30 flex flex-1 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l data-[active=true]:bg-muted/50 sm:border-l sm:border-t-0 sm:px-8 sm:py-6"
                                onClick={() => setActiveChart((prev) => (prev === chart ? null : chart))}
                            >
                                <span className="text-xs text-muted-foreground">{chartConfig[chart].label}</span>
                                <span className="text-lg font-bold leading-none sm:text-3xl">
                                    {formatNumberWithComma(total[key as keyof typeof total])}
                                </span>
                            </button>
                        )
                    })}
                </div>
            </CardHeader>
            <CardContent className="px-2 sm:p-6">
                <ChartContainer config={chartConfig} className="aspect-auto h-[250px] w-full">
                    <BarChart
                        accessibilityLayer
                        data={chart_data}
                        margin={{
                            left: 12,
                            right: 12,
                        }}
                    >
                        <CartesianGrid vertical={false} />
                        <XAxis
                            dataKey="date"
                            tickLine={false}
                            axisLine={false}
                            tickMargin={8}
                            minTickGap={32}
                            tickFormatter={(value) => {
                                const date = new Date(value)
                                return date.toLocaleDateString('en-US', {
                                    month: 'short',
                                    day: 'numeric',
                                })
                            }}
                        />
                        <YAxis
                            tickLine={false}
                            axisLine={false}
                            tickFormatter={(value) => formatNumberToNDigits(value, 2)}
                            tickMargin={8}
                        />

                        <ChartTooltip
                            content={
                                <ChartTooltipContent
                                    className="w-[150px]"
                                    nameKey="views"
                                    labelFormatter={(value) => {
                                        return new Date(value).toLocaleDateString('en-US', {
                                            month: 'short',
                                            day: 'numeric',
                                            year: 'numeric',
                                        })
                                    }}
                                />
                            }
                        />
                        {activeChart === null ? (
                            ['companies', 'jobs'].map((key) => {
                                const chart = key as keyof typeof chartConfig
                                return <Bar key={chart} stackId="a" dataKey={chart} fill={`var(--color-${chart})`} />
                            })
                        ) : (
                            <Bar dataKey={activeChart} stackId="a" fill={`var(--color-${activeChart})`} />
                        )}
                    </BarChart>
                </ChartContainer>
            </CardContent>
        </Card>
    )
}
