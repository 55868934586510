import { Button } from '@/shared/ui/base/button'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/shared/ui/base/dialog'
import { useState } from 'react'

// const formSchema = z.object({
//     name: z.string().min(1, 'Name is required'),
//     email: z.string().email('Invalid email address').min(1, 'Email is required'),
// })

function InviteMemberButton() {
    // const form = useForm<z.infer<typeof formSchema>>({
    //     resolver: zodResolver(formSchema),
    //     defaultValues: {
    //         name: '',
    //         email: '',
    //     },
    // })
    // const { toast } = useToast()
    const [open, setOpen] = useState(false)

    // function onSubmit(data: z.infer<typeof formSchema>) {
    //     console.log(data)
    //     form.reset()
    //     toast({ title: "Member added" })
    //     setOpen(false)
    // }

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant="outline">Invite members</Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Invite team members</DialogTitle>
                </DialogHeader>
                <p className="text-sm text-muted-foreground">
                    We're looking forward to welcoming your colleague to our platform! Please ask them to visit
                    app.theirstack.com and sign up to create their account. This is a vital first step for their
                    onboarding. Once their account is set up, have them send the email address used for registration to
                    founders@theirstack.com. We will then quickly add them to your team on our platform.
                </p>
                {/* <Form {...form}>
                    <form className="grid  max-w-sm items-center gap-y-4" onSubmit={form.handleSubmit(onSubmit)}>
                        <FormField
                            control={form.control}
                            name="name"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel htmlFor="name" className="col-span-1">Name</FormLabel>
                                    <FormControl>
                                        <Input type="string"  {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel htmlFor="email" className="col-span-1">Email</FormLabel>
                                    <FormControl>
                                        <Input type="string"  {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <DialogFooter className="pt-6">
                            <Button type="submit" size="sm">Invite</Button>
                        </DialogFooter>
                    </form>
                </Form> */}
            </DialogContent>
        </Dialog>
    )
}

export default InviteMemberButton
