import * as React from 'react'
import {
    ColumnDef,
    ColumnFiltersState,
    SortingState,
    VisibilityState,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'

import { DataTablePagination } from '@/components/ui/table/data-table-pagination'
import { DataTable } from '@/components/ui/table/data-table'
import { DataTableToolbarTechnologies } from './data-table-toolbar'
import { hiddenTechnologyColumns } from './colums'

interface DataTableProps<TData, TValue> {
    columns: ColumnDef<TData, TValue>[]
    data: TData[]
    isDataFetching: boolean
    sorting: SortingState
    filter_by?: string
}

export function DataTableTechnologies<TData, TValue>({
    columns,
    data,
    isDataFetching,
    sorting: initialSorting,
    filter_by,
}: DataTableProps<TData, TValue>) {
    const [rowSelection, setRowSelection] = React.useState({})
    const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>(hiddenTechnologyColumns)
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
    const [sorting, setSorting] = React.useState<SortingState>(initialSorting)

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            columnVisibility,
            rowSelection,
            columnFilters,
            pagination: {
                pageIndex: 0,
                pageSize: 500,
            },
        },
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })

    return (
        <>
            <DataTableToolbarTechnologies table={table} filter_by={filter_by} />
            <DataTable table={table} columns={columns} isDataFetching={isDataFetching} />
            <DataTablePagination table={table} totalResults={data?.length || 0} />
        </>
    )
}
