import { Company } from '@/shared/schemas/entity/company.schema'
import formatNumberToNDigits, { formatShortMoney } from '@/shared/utils/number-utils'
import { LinkedInLogoIcon } from '@radix-ui/react-icons'
import { Link2Icon } from 'lucide-react'
import CompanyLogo from '../../../../../shared/ui/company/company-logo'

export default function ModalHeader({ company }: { company: Company }) {
    return (
        <>
            <div className="flex items-center gap-3 flex-wrap">
                <div className="h-11 w-11 flex-shrink-0">
                    <CompanyLogo width={11} logo={company.logo} domain={company.domain} />
                </div>
                <div className="flex flex-col items-start gap-1">
                    <div className="flex flex-row items-center gap-2">
                        <h3 className="text-lg font-medium flex" translate="no">
                            {company.name}
                        </h3>
                        {company.url && (
                            <a href={company.url} target="_blank">
                                <Link2Icon className="w-4 h-4 text-gray-500 hover:text-gray-800" />
                            </a>
                        )}
                        {company.linkedin_url && (
                            <a href={company.linkedin_url} target="_blank">
                                <LinkedInLogoIcon className="hover:text-blue-800 w-4 h-4 text-gray-500" />
                            </a>
                        )}
                    </div>
                    <div className="flex items-center gap-2">
                        <p className="text-xs text-gray-500">
                            {company.industry && `${company.industry} · `}
                            {company.country && `${company.country} · `}
                            {company.employee_count &&
                                `${formatNumberToNDigits(company.employee_count, 1)} employees · `}
                            {company.annual_revenue_usd &&
                                `${formatShortMoney(company.annual_revenue_usd, 'usd')} revenue`}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
