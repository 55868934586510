import { z } from 'zod'
import { baseSearchParamsSchema } from './base-search-params.schema'

export const companiesSearchParamSchema = baseSearchParamsSchema.extend({
    company_domain_or: z.array(z.string()).optional(),
    company_domain_not: z.array(z.string()).optional(),
    only_yc_companies: z.boolean().optional().nullable(),
    min_employee_count: z.number().optional().nullable(),
    max_employee_count: z.number().optional().nullable(),
    min_employee_count_or_null: z.number().optional().nullable(),
    max_employee_count_or_null: z.number().optional().nullable(),
    min_funding_usd: z.number().optional().nullable(),
    max_funding_usd: z.number().optional().nullable(),
    min_revenue_usd: z.number().optional().nullable(),
    max_revenue_usd: z.number().optional().nullable(),
    max_rank: z.number().optional().nullable(),
    technology_slug_or: z.array(z.string()).optional(),
    company_technology_slug_or: z.array(z.string()).optional(),
    company_technology_slug_and: z.array(z.string()).optional(),
    company_technology_slug_not: z.array(z.string()).optional(),
    company_id_or: z.array(z.string()).optional(),
    company_name_or: z.array(z.string()).optional(),
    company_name_case_insensitive_or: z.array(z.string()).optional(),
    company_name_not: z.array(z.string()).optional(),
    company_name_partial_match_or: z.array(z.string()).optional(),
    company_name_partial_match_not: z.array(z.string()).optional(),
    company_type: z.enum(['recruiting_agency', 'direct_employer', 'all']).optional(),
    keywords_or: z.array(z.string()).optional(),
    company_description_pattern_or: z.array(z.string()).optional(),
    company_description_pattern_not: z.array(z.string()).optional(),
    company_location_pattern_or: z.array(z.string()).optional(),
    funding_stage_or: z.array(z.string()).optional(),
    company_list_id_or: z.array(z.number()).optional(),
    company_list_id_not: z.array(z.number()).optional(),
    company_country_code_or: z.array(z.string()).optional(),
    company_investors_or: z.array(z.string()).optional(),
    industry_id_or: z.array(z.number()).optional(),
    industry_id_not: z.array(z.number()).optional(),
    companies_urls: z.array(z.string()).optional(),
    company_linkedin_url_exists: z.boolean().optional(),
    company_linkedin_url_or: z.array(z.string()).optional(),
    revealed_company_data: z.boolean().optional(),
    tech_filters: z
        .object({
            min_jobs: z.number().optional().nullable(),
            max_jobs: z.number().optional().nullable(),
            min_relative_occurrence: z.number().optional().nullable(),
            first_date_found_lte: z.string().optional().nullable(),
            first_date_found_gte: z.string().optional().nullable(),
            last_date_found_lte: z.string().optional().nullable(),
            last_date_found_gte: z.string().optional().nullable(),
            confidence_or: z.array(z.string()).optional(),
            technology_category_slug_or: z.array(z.string()).optional(),
        })
        .optional(),
    job_filters: z
        .object({
            job_title_or: z.array(z.string()).optional(),
            job_title_not: z.array(z.string()).optional(),
            job_title_pattern_and: z.array(z.string()).optional(),
            job_title_pattern_or: z.array(z.string()).optional(),
            job_title_pattern_not: z.array(z.string()).optional(),
            job_country_code_or: z.array(z.string()).optional(),
            job_country_code_not: z.array(z.string()).optional(),
            posted_at_max_age_days: z.number().optional(),
            posted_at_gte: z.string().optional(),
            posted_at_lte: z.string().optional(),
            discovered_at_max_age_days: z.number().optional(),
            discovered_at_min_age_days: z.number().optional(),
            discovered_at_gte: z.string().optional(),
            discovered_at_lte: z.string().optional(),
            min_salary_usd: z.number().optional(),
            max_salary_usd: z.number().optional(),
            remote: z.boolean().optional(),
            job_description_pattern_or: z.array(z.string()).optional(),
            job_description_pattern_not: z.array(z.string()).optional(),
            only_jobs_with_reports_to: z.boolean().optional(),
            only_jobs_with_hiring_managers: z.boolean().optional(),
            job_technology_slug_or: z.array(z.string()).optional(),
            job_technology_slug_and: z.array(z.string()).optional(),
            job_technology_slug_not: z.array(z.string()).optional(),
            job_location_pattern_or: z.array(z.string()).optional(),
            job_location_pattern_not: z.array(z.string()).optional(),
            scraper_name_pattern_or: z.array(z.string()).optional(),
        })
        .optional(),
})

export type CompaniesSearchParams = z.infer<typeof companiesSearchParamSchema>
