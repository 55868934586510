import { DAY_IN_MS } from '@/shared/utils/date-utils'
import { Company } from '@/shared/schemas/entity/company.schema'
import { TechSearchParams } from '@/schemas/request/tech-search-params.schema'
import { TechDetailsResponse } from '@/schemas/responses/tech-details-response.schema'
import { getTechDetailsFromSearchParams } from '@/services/tech-stack.service'
import { PaginationState } from '@tanstack/react-table'
import { useQuery } from '@tanstack/react-query'
import { technologiesColumns } from './colums'
import { DataTableTechnologies } from './data-table'

export default function TechnologiesTab({ company }: { company: Company }) {
    const search_params: TechSearchParams = { company_name_or: [company.name] }
    const pagination: PaginationState = { pageIndex: 0, pageSize: 5000 }
    const { data: technologies, isFetching } = useQuery<TechDetailsResponse>({
        queryKey: ['technologies', company.name],
        queryFn: () => getTechDetailsFromSearchParams(search_params, pagination, false),
        staleTime: DAY_IN_MS,
    })

    return (
        <>
            {company.technology_slugs && (
                <DataTableTechnologies
                    data={technologies?.data ? technologies?.data : []}
                    columns={technologiesColumns}
                    isDataFetching={isFetching}
                    sorting={[]}
                />
            )}
        </>
    )
}
