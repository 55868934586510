import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/ui/base/alert-dialog'
import { forwardRef, useImperativeHandle, useState } from 'react'
import CreditsBadge from '../credits/credits-badge'
import { OpenDialogHandle } from './dialog-handle-interface'

export interface ConfirmRevealCompanyDialogProps {
    n_companies: number
    confirmAction: () => void
    cancelAction?: () => void
    children?: React.ReactNode
    defaultOpen?: boolean
}

export const ConfirmRevealCompanyDialog = forwardRef<OpenDialogHandle, ConfirmRevealCompanyDialogProps>(
    ({ n_companies, confirmAction, children, cancelAction = () => {}, defaultOpen = false }, ref) => {
        const [show, setShow] = useState(defaultOpen)
        useImperativeHandle(ref, () => ({
            open: () => setShow(true),
            close: () => setShow(false),
        }))

        return (
            <AlertDialog open={show} onOpenChange={(open) => setShow(open)}>
                {children && <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>}
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>
                            {n_companies == 1
                                ? 'Do you want reveal this company?'
                                : `Do you want reveal ${n_companies} companies?`}
                        </AlertDialogTitle>
                        <AlertDialogDescription>
                            This action will consume <CreditsBadge credits={n_companies} /> from your account.
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel onClick={cancelAction}>Cancel</AlertDialogCancel>
                        <AlertDialogAction onClick={confirmAction}>Continue</AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        )
    }
)
