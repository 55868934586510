import { getCompanyFromList, getCompanyList, renameList } from '@/services/company_list.service'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { companyColumns } from './components/data-table-columns-company-list-details'
import { CompanyList } from '@/schemas/entities/company-list.schema'
import { DataTableWithPagination } from './components/data-table'
import { CompanyFromListSearchParams } from '@/schemas/request/companies-from-list-search-params.schema'
import { CompanyFromList } from '@/schemas/entities/companies-from-company-list'
import { useFetchDataForTable } from './components/use-fetch-data-for-table'
import SearchTitleInput from '@/components/ui/search-title-input'
import { useToast } from '@/components/ui/base/use-toast'
import { useErrorNotification } from '@/components/hooks/toast/use-error-notification'
import { PageLayout } from '@/components/ui/layout/page_layout'
import { useQuery } from '@tanstack/react-query'
import { HOUR_IN_MS } from '@/shared/utils/date-utils'

const CompanyListDetailsPage: React.FC = () => {
    const { listId } = useParams<{ listId: string }>()
    const { data: list, refetch } = useQuery<CompanyList>({
        queryKey: ['companyList', listId],
        queryFn: () => getCompanyList(+(listId || 0)),
        enabled: !!listId,
        staleTime: HOUR_IN_MS,
    })
    const [savedSearch] = useState<CompanyFromListSearchParams>({
        list_id: listId ? +listId : 0,
        order_by: [{ field: 'added_at_last_time', desc: true }],
    })
    const { table, isDataFetching, searchedSP, onChangeFilters } = useFetchDataForTable<
        CompanyFromListSearchParams,
        CompanyFromList
    >(savedSearch, getCompanyFromList, companyColumns, list?.companies_count || 0)
    const { toast } = useToast()
    const { showErrorNotification } = useErrorNotification({ isError: false })

    useEffect(() => {
        if (list) document.title = list.name + ' · List · TheirStack'
    }, [list])

    const handleRenameCompanyList = (name: string) => {
        if (!listId || !list) return
        renameList(+listId, name)
            .then(() => {
                refetch()
                toast({ title: 'Search renamed' })
            })
            .catch(() => {
                showErrorNotification()
            })
    }

    return (
        <PageLayout>
            <div className="flex flex-col gap-4 h-full flex-grow overflow-hidden">
                {!list && <p>Loading...</p>}
                {list && (
                    <>
                        <div className="flex items-center justify-between">
                            <div>
                                {list && list.name == 'Companies seen' && (
                                    <h2 className="text-2xl font-medium tracking-tight">{list?.name}</h2>
                                )}
                                {list && list.name != 'Companies seen' && (
                                    <SearchTitleInput name={list?.name} updateName={handleRenameCompanyList} />
                                )}
                            </div>
                        </div>
                        {searchedSP && (
                            <DataTableWithPagination
                                columns={companyColumns}
                                filter_by="name"
                                isDataFetching={isDataFetching}
                                totalResults={list.companies_count}
                                onChangeFilters={onChangeFilters}
                                searchedSP={searchedSP}
                                table={table}
                            />
                        )}
                    </>
                )}
            </div>
        </PageLayout>
    )
}

export default CompanyListDetailsPage
