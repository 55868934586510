import { Button } from '@/shared/ui/base/button'
import { Input } from '@/components/ui/base/input'
import { Label } from '@/components/ui/base/label'
import { Separator } from '@/shared/ui/base/separator'
import { useToast } from '@/components/ui/base/use-toast'
import { HOUR_IN_MS, MINUTE_IN_MS } from '@/shared/utils/date-utils'
import { UserWithTeam } from '@/schemas/entities/user'
import { getMyProfile } from '@/services/user.service'
import { CopyIcon } from 'lucide-react'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { APIRequestChart } from './api-request-chart'
import { CreditsConsumption } from '@/schemas/entities/credits-consumption.schema'
import { getCreditsConsumption } from '@/services/billing.service'
import { Spinner } from '@/shared/ui/base/spinner'

function APIPage() {
    const { data: me = null } = useQuery<UserWithTeam>({
        queryKey: ['me'],
        queryFn: getMyProfile,
        staleTime: HOUR_IN_MS,
    })

    const { data: creditsConsumption = null } = useQuery<CreditsConsumption[]>({
        queryKey: ['credits-consumption'],
        queryFn: getCreditsConsumption,
        staleTime: MINUTE_IN_MS,
    })

    const { toast } = useToast()
    useEffect(() => {
        document.title = 'API Key · Settings · TheirStack'
    }, [])

    const handleOnClick = () => {
        if (!me) return
        navigator.clipboard.writeText(me.token)
        toast({ title: 'Copied to clipboard' })
    }

    return (
        <div className="space-y-6">
            <div>
                <h3 className="text-lg font-medium">API</h3>
                <p className="text-sm text-muted-foreground">Manage your API access and usage.</p>
            </div>
            <Separator />
            <div className="space-y-3">
                <Label>API Key</Label>
                <p className="text-sm text-muted-foreground">
                    API keys allow you to access your account programmatically via the&nbsp;
                    <a
                        href="https://api.theirstack.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline"
                    >
                        TheirStack API
                    </a>
                    . Please keep your key secret, it can be used to access your account without a password.
                </p>
                <div className="flex space-x-2">
                    <Input
                        disabled={true}
                        className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 w-80"
                        value="XXXXXXXXXXXXXXXXXXXXXXX"
                    />
                    <Button variant="outline" onClick={handleOnClick}>
                        <CopyIcon className="h-4 w-4" strokeWidth={1.5} />
                    </Button>
                </div>
            </div>
            <div className="space-y-3">
                {creditsConsumption !== null && <APIRequestChart data={creditsConsumption} />}
                {creditsConsumption === null && (
                    <div className="flex justify-center">
                        <Spinner size="default" className="m-1" />
                    </div>
                )}
            </div>
        </div>
    )
}

export default APIPage
