import { useKindeAuth } from '@kinde-oss/kinde-auth-react'
import {
    cleanLocalStorage,
    isTokenValid as isLocalStorageTokenValid,
    setTokenAndExpirationDate,
} from '@/services/auth/auth.service'
import { useEffect, useRef, useState } from 'react'
import { useEventListener } from 'usehooks-ts'

export function useAuthentication() {
    const [isLoading, setIsLoading] = useState(true)
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const {
        isAuthenticated: isKindeAuthenticated,
        isLoading: isKindeLoading,
        login,
        getToken: getTokenFromKinde,
        logout: logoutKinde,
        user,
        register,
    } = useKindeAuth()
    const documentRef = useRef<Document>(document)

    const onUserComingBackToTheTab = () => {
        if (document.visibilityState === 'visible') {
            if (!isLocalStorageTokenValid()) {
                getTokenFromKinde().then((token) => {
                    if (token) {
                        setTokenAndExpirationDate(token)
                        setIsAuthenticated(true)
                    }
                    setIsLoading(false)
                })
            }
        }
    }

    useEventListener('visibilitychange', onUserComingBackToTheTab, documentRef)

    const logout = () => {
        cleanLocalStorage()
        logoutKinde()
    }

    useEffect(() => {
        if (isKindeLoading) return

        if (!isKindeAuthenticated) {
            cleanLocalStorage()
            setIsLoading(false)
            return
        }

        if (isLocalStorageTokenValid()) {
            setIsAuthenticated(true)
            setIsLoading(false)
            return
        }

        getTokenFromKinde().then((token) => {
            if (token) {
                setTokenAndExpirationDate(token)
                setIsAuthenticated(true)
            }
            setIsLoading(false)
        })
    }, [isKindeAuthenticated, isKindeLoading])

    return { isLoading, isAuthenticated, login, logout, user, register }
}

export type KindeUser = {
    given_name: string | null
    id: string | null
    family_name: string | null
    email: string | null
    picture: string | null
}

export interface State {
    redirectTo?: string
}
