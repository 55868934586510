'use client'

import { useState } from 'react'
import { Button } from '@/shared/ui/base/button'
import { BillingProduct } from '@/shared/schemas/entity/billing-product.schema'
import formatNumberToNDigits, { formatMoney, formatStripeNumber } from '@/shared/utils/number-utils'
import { Badge } from '@/shared/ui/base/badge'
import { Currency } from '@/shared/schemas/response/geolocation.schema'
import PricingCard from '../pricing-card'

export const getCreditPrice = (unit_amount: number, credits: string | undefined, currency: Currency) => {
    return formatMoney(formatStripeNumber(unit_amount) / +(credits ?? 1), currency, 4)
}

const datasetProducts = [
    {
        name: 'Jobs',
        records: 60000000,
        amount: 1200000,
        record_name: 'job',
        description: '60M historical jobs and 120k new jobs added every day',
    },
    {
        name: 'Tecnographics',
        records: 41000000,
        amount: 1200000,
        record_name: 'technology company',
        description: '41M records matching 4.6M companies with +20k technologies ',
    },
]

export default function DatasetPricing({
    currency,
    onBillingPriceSelected,
}: {
    product: BillingProduct
    currency: Currency
    onBillingPriceSelected: (price_id: string) => void
}) {
    const [selectedDataset, setSelectedDataset] = useState(datasetProducts[0])

    return (
        <div className="flex flex-col gap-4">
            <p className="text-xs md:text-sm text-gray-500">
                Our datasets plans offer full access to the best jobs and technology usage datasets.{' '}
                <a href="https://theirstack.com/datasets" target="_blank" className="text-primary underline">
                    Learn more about our datasets
                </a>
            </p>
            <div className="w-full grid grid-cols-1 md:grid-cols-4 gap-2">
                <div className="md:col-span-3 flex flex-col gap-2 w-full">
                    {datasetProducts.map((item) => (
                        <PricingCard
                            key={item.name}
                            active={selectedDataset == item}
                            onClick={() => setSelectedDataset(item)}
                            className="p-1"
                        >
                            <span className="flex flex-col items-start gap-1">
                                <p className="text-sm md:text-base font-medium">{item.name + ' dataset'}</p>
                                <p className="text-xs md:text-sm text-gray-500">{item.description}</p>
                            </span>
                            <span className="flex flex-col items-end gap-1">
                                <p className="text-sm md:text-base">
                                    {' '}
                                    {formatMoney(formatStripeNumber(1200000), currency)} +{' '}
                                    {formatMoney(formatStripeNumber(400000), currency)}
                                    <span className="text-xs font-medium text-gray-600 ml-1">{`/month`}</span>
                                </p>
                                <p className="hidden md:inline-block text-sm text-gray-400">
                                    {getCreditPrice(item.amount, item.records.toString(), currency)} per{' '}
                                    {item.record_name}
                                </p>
                            </span>
                        </PricingCard>
                    ))}
                </div>
                {selectedDataset && (
                    <div className="col-span-1 flex justify-center items-center bg-gray-100 rounded-md p-2">
                        <div className="flex flex-col items-center gap-4">
                            <Badge variant="outline">Dataset</Badge>
                            <p className="text-lg md:text-2xl">{selectedDataset.name}</p>
                            <p className="text-4xl ">
                                {formatMoney(formatStripeNumber(400000), currency)}
                                <span className="text-xs font-medium text-gray-600 ml-1">{`/month`}</span>
                            </p>
                            <p className="text-lg ">
                                {formatNumberToNDigits(+selectedDataset.records, 2)} records returned
                            </p>
                            <p className=" text-sm text-gray-400">
                                {getCreditPrice(selectedDataset.amount, selectedDataset.records.toString(), currency)}{' '}
                                per {selectedDataset.record_name}
                            </p>
                            <Button onClick={() => onBillingPriceSelected('contact_sales')}>Contact sales</Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
