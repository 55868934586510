import { UserWithTeam, getActivePlan, getTotalCredits, isFreePlan } from '@/schemas/entities/user'
import { getMyProfile } from '@/services/user.service'
import { useQuery } from '@tanstack/react-query'
import { Card } from '../../../../shared/ui/base/card'
import { Progress } from '../../base/progress'
import { Link } from 'react-router-dom'
import { formatNumberWithComma } from '@/shared/utils/number-utils'
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '../../base/dropdown-menu'
import { Button } from '../../../../shared/ui/base/button'
import { THERE_MINUTE_IN_MS } from '@/shared/utils/date-utils'
import { useEffect } from 'react'
import { setBillingPlan } from '@/services/tracking.service'
import CreditsIcon from '../../credits/credits-icon'

export default function CreditsButton() {
    const { data: me, refetch } = useQuery<UserWithTeam>({
        queryKey: ['me'],
        queryFn: getMyProfile,
        staleTime: THERE_MINUTE_IN_MS,
    })
    const usage_percentage = me && me.team ? me?.team.credits_used_current_period / getTotalCredits(me) : 0
    const showCredits = me && me.team && ((isFreePlan(me) && me.is_superuser == false) || usage_percentage > 0.8)

    useEffect(() => {
        if (me) {
            setBillingPlan(isFreePlan(me), getActivePlan(me)?.name || '')
        }
    }, [me])

    return (
        <>
            {me && me.team && (
                <DropdownMenu onOpenChange={() => refetch()}>
                    <DropdownMenuTrigger asChild>
                        <div>
                            {showCredits && (
                                <Button variant="outline" className="flex space-x-1">
                                    <p className="text-sm">
                                        {me.team.credits_left_current_period > 0
                                            ? me.team.credits_left_current_period
                                            : 0}
                                    </p>
                                    <CreditsIcon className="w-5 h-5" />
                                </Button>
                            )}
                            {!showCredits && (
                                <Button variant="outline">
                                    <CreditsIcon className="w-5 h-5" />
                                </Button>
                            )}
                        </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-56 p-2" align="end" forceMount>
                        <div className="flex flex-col space-y-2">
                            <h2 className="text-sm font-semibold">Credits</h2>
                            <div className="flex items-center space-x-2 justify-start pb-1 mt-2">
                                <p className="text-xs text-gray-500">
                                    {' '}
                                    {formatNumberWithComma(me.team.credits_used_current_period)} used of{' '}
                                    {formatNumberWithComma(getTotalCredits(me))}
                                </p>
                            </div>
                            <Progress
                                className="h-1"
                                value={(me.team.credits_used_current_period / getTotalCredits(me)) * 100}
                            />

                            <Link to={`/settings/billing`} className="hover:cursor-pointer underline text-sm">
                                {' '}
                                Manage billing{' '}
                            </Link>
                            <a
                                href="https://theirstack.com/en/pricing#faqs"
                                target="_blank"
                                className="hover:cursor-pointer underline text-sm"
                            >
                                {' '}
                                How credits work{' '}
                            </a>
                        </div>
                    </DropdownMenuContent>
                </DropdownMenu>
            )}
        </>
    )
}

export function CreditsCard({ me }: { me: UserWithTeam }) {
    return (
        <Card className="h-10 flex flex-col center px-2 place-content-center ">
            {me.team && (
                <>
                    <div className="flex items-center space-x-2 justify-center pb-1">
                        <p className="text-sm"> Credits</p>
                        <p className="text-xs text-gray-500">
                            {' '}
                            {formatNumberWithComma(me.team.credits_used_current_period)} /{' '}
                            {formatNumberWithComma(getTotalCredits(me))}
                        </p>
                    </div>
                    <Progress
                        className="h-1"
                        value={(me.team.credits_used_current_period / (me.team.credits || 0)) * 100}
                    />
                </>
            )}
        </Card>
    )
}
